import getTrainsPolicyList from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getTrainsPolicyList";
import delTravelPolicyByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_delTravelPolicyByBusinessType';

export default {
  name: "evectionTrainPolicy",
  data() {
    return {
      loading: false,
      trainsPolicyList: []
    };
  },
  created() {
  },
  mounted(){
   },
  activated () {
    this.getTrainsPolicyList()
  },
  methods: {
    async getTrainsPolicyList() {
      try {
        this.loading = true
        const res = await getTrainsPolicyList()
        this.trainsPolicyList = res.datas
      } catch (error) {
        this.trainsPolicyList = []
      } finally {
        this.loading = false
      }
    },
    addEvectionTrainPolicy() {
      this.$router.push({
        name: 'admin-evection-train-policy-edit',
        query: {
          id: 0,
          type: 'add'
        }
      })
    },
    deleteEvectionTrainPolicy(val) {
      this.$confirm('确定删除此政策吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const res = await delTravelPolicyByBusinessType({ id: val.id, businessType: 2 })
          this.getTrainsPolicyList()
      }).catch(() => {
      });
    },
    editEvectionTrainPolicy(val) {
      this.$router.push({
        name: 'admin-evection-train-policy-edit',
        query: {
          id: val.id,
          type: 'edit'
        }
      })
    }
  }
}

/*
*获取火车票政策列表
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/web/policyConfig/getTrainsPolicyList',
    data: data
  }
  return __request(pParameter)
}
export default request